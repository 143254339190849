import React from "react"
import styled from "styled-components"
import { rgba } from "polished"
import { Container, Row, Col } from "react-bootstrap"

import { Title, Section, Text, Span } from "../../../components/Core"
import { device } from "../../../utils"
import MainImage from "../../../assets/image/jpeg/active-acne.jpg"

const Iwrap = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
`

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span {
    color: #00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`

const Thirdtext = styled.div`
  opacity: 1;
  transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;

  width: 90%;
  color: rgba(255, 255, 255, 0.7);

  color: #000;
  font-size: 1.6em;
  line-height: 1.5;

  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
    font-size: 22px;
    text-align: center;
    width: 100%;
  }
`

const Secti = styled.section`
  position: relative;

  @media screen and (max-width: 767px) {
    padding-top: 1em;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
`

const Sect = styled.section`
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
`

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 0;

  @media ${device.sm} {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 9px;
      margin-right: 13px;
    }
  }
`

const BAcnffeCassusesPage = () => (
  <>
    {/* <!-- Content section 1 --> */}

   
      <Section bg="#ffffff" py={4}>
        <Container className="pt-3 pb-3">
          <Row className="pb-4 align-items-center">
            <Col lg="12" className="order-lg-1 mt-lg-0  pb-lg-5">
              <div
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <div>
                  <Iwrap>
                    <Itext as="h2">Dark Circles <span>London</span></Itext>
                  </Iwrap>
                  <SecondText>Symptoms and Causes of Dark Circles</SecondText>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center pb-2">
            <Col lg="12">
              <Text>
                Dark circles can be caused by a number of factors, including:
                <br /> <br />
                Genetic: Dark circles beneath the eyes may be genetic and run in
                families in some situations. Dark circles may be more common in
                people who have naturally thinner or more transparent skin.
                <br /> <br />
                Age: As we become older, the skin under our eyes thins and loses
                suppleness, making the blood vessels beneath it more visible
                thus creating dark circles.
                <br /> <br />
                Sleep deprivation: Sleep deprivation can cause the skin to
                become paler, making the blood vessels beneath the skin more
                visible and contributing to the appearance of dark circles.
                <br /> <br />
                Allergies: Allergy or hay fever can cause inflammation and
                swelling in the eyes, making blood vessels apparent and giving
                birth to dark circles.
                <br /> <br />
                Dehydration: When the body is dehydrated, the skin becomes dull
                and blood vessels become more visible, resulting in the
                appearance of dark circles.
                <br /> <br />
                Sun exposure: It causes the skin to create more melanin, which
                can contribute to the appearance of dark circles.
                <br /> <br />
              </Text>
            </Col>
          </Row>

          <Row>
            <Col>
              <Text>
                The formation of dark, discoloured areas under the eyes is the
                most prevalent indication of dark circles. <br /> <br />
                Maintaining an excellent sleep routine, staying hydrated,
                avoiding excessive sun exposure, and treating any underlying
                allergies or medical issues that may be contributing to the
                problem are all key ways to prevent or lessen the appearance of
                dark circles.
              </Text>
            </Col>
          </Row>
        </Container>
      </Section>

  </>
)

export default BAcnffeCassusesPage
